import { Box, Flex, Text, Input, Button, Checkbox } from "@chakra-ui/react";
import ButtonComponent from "../common/button";

export default function loginForm({
  loginErrors,
  loginFormValues,
  validateLoginForm,
  changeLoginFormValues,
}) {
  const { email, password } = loginFormValues;
  return (
    <Flex width="100%" flexDirection="column">
      <Box mt="5">
        <Text variant="productCardDescription" as="b">
          Username or email address
        </Text>
        <Input
          size="lg"
          name="email"
          type="email"
          value={email}
          variant="outline"
          focusBorderColor="maroon"
          onChange={changeLoginFormValues}
        />
        <Text fontSize="13px" fontWeight="600" color="red">
          {loginErrors && loginErrors.email}
        </Text>
      </Box>
      <Box mt="3">
        <Text variant="productCardDescription" as="b">
          Password
        </Text>
        <Input
          size="lg"
          type="password"
          name="password"
          value={password}
          variant="outline"
          focusBorderColor="maroon"
          onChange={changeLoginFormValues}
        />
        <Text fontSize="13px" fontWeight="600" color="red">
          {loginErrors && loginErrors.password}
        </Text>
      </Box>
      {/* <Box mt="1">
        <Checkbox size="lg" color="detailsColor">
          Remember me
        </Checkbox>
      </Box> */}
      <Box mt="7">
        <ButtonComponent
          px="20px"
          title="Log in"
          borderRadius={4}
          backgroundColor="maroon"
          handleClick={validateLoginForm}
        />
      </Box>
      {/* <Box>
        <Button variant="link" color="detailsColor">
          Lost your password?
        </Button>
      </Box> */}
    </Flex>
  );
}
