import { fetchPost } from "./serviceClient";

export const createUser = async (body) => {
  return await fetchPost(
    `${process.env.NEXT_PUBLIC_NEXTJS_API_URL}/user/register-user`,
    body
  );
};
export const loginUser = async (body) => {
  return await fetchPost(
    `${process.env.NEXT_PUBLIC_NEXTJS_API_URL}/user/login-user`,
    body
  );
};
export const sendResetPasswordLink = async (body) => {
  return await fetchPost(
    `${process.env.NEXT_PUBLIC_NEXTJS_API_URL}/user/send-reset-password-link`,
    body
  );
};
export const resetPassword = async (body) => {
  return await fetchPost(
    `${process.env.NEXT_PUBLIC_NEXTJS_API_URL}/user/reset-password`,
    body
  );
};
