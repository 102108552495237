import { useState } from "react";
import toaster from "toasted-notes";
import { useRouter } from "next/router";
import { Flex, Text, Center, VStack } from "@chakra-ui/react";

import Cookies from "js-cookie";
import { wrapper } from "../src/redux/store";
import Error from "../src/components/common/error";
import TopBand from "../src/components/common/topBand";
import LoginForm from "../src/components/myAccount/loginForm";
import RegisterForm from "../src/components/myAccount/registerForm";
import ErrorToaster from "../src/components/common/error/errorToaster";

import { loginUser, createUser } from "../src/services/user";
import { fetchProductCategories } from "../src/redux/actions";

import {
  LOGIN_FORM_SCHEMA,
  DEFAULT_LOGIN_FORM,
  REGISTER_FORM_SCHEMA,
  DEFAULT_REGISTER_FORM,
} from "../src/constants";

const validateLoginSchema = (values) =>
  LOGIN_FORM_SCHEMA.validate(values, {
    abortEarly: false,
  });
const validateRegisterSchema = (values) =>
  REGISTER_FORM_SCHEMA.validate(values, {
    abortEarly: false,
  });

export default function Login({ errorMessage }) {
  if (errorMessage) {
    return <Error errorMessage={errorMessage} />;
  }

  const router = useRouter();

  const [loginErrors, setLoginErrors] = useState(null);
  const [registerErrors, setRegisterErrors] = useState(null);

  const [registerFormValues, setRegisterFormValues] = useState(
    DEFAULT_REGISTER_FORM
  );
  const [loginFormValues, setLoginFormValues] = useState(DEFAULT_LOGIN_FORM);

  const handleChangeLoginFormValues = (e) => {
    setLoginFormValues(
      Object.assign({}, loginFormValues, {
        [e.target.name]: e.target.value,
      })
    );
  };

  const handleChangeRegisterFormValues = (e) => {
    setRegisterFormValues(
      Object.assign({}, registerFormValues, {
        [e.target.name]: e.target.value,
      })
    );
  };

  const handleValidateRegisterForm = () => {
    validateRegisterSchema(registerFormValues)
      .then(() => {
        if (!checkPasswordsMatch(registerFormValues)) {
          setRegisterErrors(
            Object.assign(
              {},
              {
                confirmPassword: "Passwords do not match.",
              }
            )
          );
        } else {
          setRegisterErrors(null);
          registerWpUser();
        }
      })
      .catch((err) => {
        if (err.inner) {
          setRegisterErrors(
            err.inner.reduce((acc, currentValue) => {
              acc[currentValue.path] = currentValue.message;
              return acc;
            }, {})
          );
        } else {
          console.log(err);
        }
      });
  };

  const handleValidateLoginForm = () => {
    validateLoginSchema(loginFormValues)
      .then(() => {
        setLoginErrors(null);
        loginWpUser();
      })
      .catch((err) => {
        if (err.inner) {
          setLoginErrors(
            err.inner.reduce((acc, currentValue) => {
              acc[currentValue.path] = currentValue.message;
              return acc;
            }, {})
          );
        } else {
          console.log(err);
        }
      });
  };

  const checkPasswordsMatch = (formValues) => {
    const { password, confirmPassword } = formValues;
    return password === confirmPassword;
  };

  const registerWpUser = async () => {
    try {
      const response = await createUser(registerFormValues);
      if (response && response.success && response.data) {
        Cookies.set(
          process.env.NEXT_PUBLIC_NEXTJS_COOKIE,
          response.data.token,
          { expires: 60 }
        );
        router.push("/my-account");
      } else {
        toaster.notify(<ErrorToaster errorMessage={response.message} />, {
          duration: 3000,
          position: "top",
        });
      }
    } catch (err) {
      toaster.notify(<ErrorToaster errorMessage={err.message} />, {
        duration: 3000,
        position: "top",
      });
    }
  };
  const loginWpUser = async () => {
    try {
      const response = await loginUser(loginFormValues);
      if (response && response.success && response.data) {
        Cookies.set(
          process.env.NEXT_PUBLIC_NEXTJS_COOKIE,
          response.data.token,
          { expires: 60 }
        );
        router.push("/my-account");
      } else {
        toaster.notify(<ErrorToaster errorMessage={response.message} />, {
          duration: 3000,
          position: "top",
        });
      }
    } catch (err) {
      toaster.notify(<ErrorToaster errorMessage={err.message} />, {
        duration: 3000,
        position: "top",
      });
    }
  };

  return (
    <>
      <TopBand title="My Account" />
      <Center width="100%" my="50px">
        <VStack width="100%">
          <Flex
            flexWrap="wrap"
            justifyContent="space-between"
            width={{ base: "90%", "2xl": "1350px" }}
            flexDirection={{ base: "column", lg: "row" }}
          >
            <Flex width={{ base: "100%", lg: "45%" }} flexDirection="column">
              <Text variant="drawerTitleText" alignSelf="flex-start">
                Login
              </Text>
              <LoginForm
                loginErrors={loginErrors}
                loginFormValues={loginFormValues}
                validateLoginForm={handleValidateLoginForm}
                changeLoginFormValues={handleChangeLoginFormValues}
              />
              <Text
                mt="2"
                _hover={{
                  cursor: "pointer",
                }}
                color="maroon"
                fontSize="13px"
                fontWeight="600"
                onClick={() => router.push("/forgot-password")}
              >
                Forgot your password?
              </Text>
            </Flex>
            <Flex
              flexDirection="column"
              mt={{ base: 10, lg: 0 }}
              width={{ base: "100%", lg: "45%" }}
            >
              <Text variant="drawerTitleText" alignSelf="flex-start">
                Register
              </Text>
              <RegisterForm
                registerErrors={registerErrors}
                registerFormValues={registerFormValues}
                validateRegisterForm={handleValidateRegisterForm}
                changeRegisterFormValues={handleChangeRegisterFormValues}
              />
            </Flex>
          </Flex>
        </VStack>
      </Center>
    </>
  );
}

export const getStaticProps = wrapper.getStaticProps((store) => async () => {
  try {
    await store.dispatch(fetchProductCategories());
  } catch (err) {
    return {
      props: {
        errorMessage: err.message,
      },
    };
  }
});
