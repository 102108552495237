import { Box, Flex, Text, Input } from "@chakra-ui/react";
import ButtonComponent from "../common/button";

export default function registerForm({
  registerErrors,
  registerFormValues,
  validateRegisterForm,
  changeRegisterFormValues,
}) {
  const { email, password, confirmPassword } = registerFormValues;
  return (
    <Flex width="100%" flexDirection="column">
      <Box mt="5">
        <Text variant="productCardDescription" as="b">
          Email address
        </Text>
        <Input
          size="lg"
          name="email"
          type="email"
          value={email}
          variant="outline"
          focusBorderColor="maroon"
          onChange={changeRegisterFormValues}
        />
        <Text fontSize="13px" fontWeight="600" color="red">
          {registerErrors && registerErrors.email}
        </Text>
      </Box>
      <Box mt="3">
        <Text variant="productCardDescription" as="b">
          Password
        </Text>
        <Input
          size="lg"
          type="password"
          name="password"
          value={password}
          variant="outline"
          focusBorderColor="maroon"
          onChange={changeRegisterFormValues}
        />
        <Text fontSize="13px" fontWeight="600" color="red">
          {registerErrors && registerErrors.password}
        </Text>
      </Box>
      <Box mt="3">
        <Text variant="productCardDescription" as="b">
          Confirm password
        </Text>
        <Input
          size="lg"
          variant="outline"
          type="password"
          name="confirmPassword"
          value={confirmPassword}
          focusBorderColor="maroon"
          onChange={changeRegisterFormValues}
        />
        <Text fontSize="13px" fontWeight="600" color="red">
          {registerErrors && registerErrors.confirmPassword}
        </Text>
      </Box>
      <Box mt="7">
        <ButtonComponent
          px="20px"
          title="Register"
          borderRadius={4}
          backgroundColor="maroon"
          handleClick={validateRegisterForm}
        />
      </Box>
    </Flex>
  );
}
